.container {
  width: 100vw;
  display: grid;
  align-self: center;
  justify-self: center;
  background-color: var(--selected-pure-color);
  border-top: 1px solid var(--selected-shadow-color);
  border-bottom: 1px solid var(--selected-shadow-color);
  margin: 0 auto;
  margin-top: 1rem;

  & > :nth-child(1) {
    display: inline-block;
    width: 38rem;
    max-width: 85vw;
    margin: 0 auto;

    & > :nth-child(1) {
      width: 100%;
      margin-top: 18px;
    }

    & > :nth-child(n) {
      width: 100%;
      margin-bottom: 18px;
    }
  }

  @media (--viewport-s) {
    border: 1px solid var(--selected-shadow-color);
    max-width: 40rem;
    border-radius: 10px;
  }
}
